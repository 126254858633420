import { actionTypes } from './actions'

export default function appReducer(state, action) {
  switch (action.type) {
    case actionTypes.CLOSE_CONTACT_MODAL:
      return { ...state, isContactModalOpen: false }

    case actionTypes.OPEN_CONTACT_MODAL:
      return { ...state, isContactModalOpen: true }

    default:
      return { ...state }
  }
}
