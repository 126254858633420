import React, { createContext, useReducer, useMemo } from 'react'
import PropTypes from 'prop-types'

import appReducer from './reducers'

export const AppContext = createContext({ state: {}, dispatch: {} })

export const initialState = {
  isContactModalOpen: false,
}

export function AppProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, initialState)
  const store = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>
}
AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function AppWrapper({ element }) {
  return <AppProvider>{element}</AppProvider>
}
AppWrapper.propTypes = {
  element: PropTypes.node.isRequired,
}
