export const actionTypes = {
  OPEN_CONTACT_MODAL: 'OPEN_CONTACT_MODAL',
  CLOSE_CONTACT_MODAL: 'CLOSE_CONTACT_MODAL',
}

export function openContactModal() {
  return {
    type: actionTypes.OPEN_CONTACT_MODAL,
  }
}

export function closeContactModal() {
  return {
    type: actionTypes.CLOSE_CONTACT_MODAL,
  }
}
